<template>
  <div class="pageWrapper">
    <!-- Shop description -->
    <SaveDiscardMenu
      :saveChanges="update"
      :isChanged="isChanged"
      :discardChanges="discard"
      :displayDelete="true"
      :delete="{
        execute: removeShop,
        message: `Are you sure you want to delete ${shop.locationName}?`
      }"
      :title="shop.locationName"
    />

    <div class="storageInformation">
      <!-- Store description -->
      <div class="StoreInfo">
        <div>
          <p class="title">Store description</p>
          <StoreDescription :description="description" />
        </div>
        <div>
          <p class="title">Store contact</p>
          <StoreContact :contactInfo="contactInfo" />
        </div>
      </div>
      <!-- Shop location -->
      <div class="shopLocation">
        <p class="title">Shop location</p>
        <div class="mapBox" v-if="coordinates.length > 0">
          <MglMap
            :accessToken="accessToken"
            :mapStyle="mapStyle"
            :center="coordinates"
          >
            <MglMarker
              :draggable="true"
              @dragend="dragged($event)"
              :coordinates="coordinates"
              color="black"
            />
          </MglMap>
        </div>
      </div>
    </div>

    <!-- Ordinary opening hours -->
    <p class="title">Ordinary opening hours</p>
    <div class="days">
      <OpeningHoursOrdinaryDayCard
        v-for="opening_hour in daysData"
        :key="opening_hour.day"
        :opening_hour="opening_hour"
      />
    </div>
    <!-- Custom opening hours -->
    <div class="customOpeningHours">
      <p class="title">Custom opening hours</p>
      <MainButton
        :clickFunction="addCustomOpeningHours"
        title="Add custom opening hours"
        styleClass="primary"
      />
    </div>
    <div class="customDays">
      <OpeningHoursCustomDayCard
        v-for="(customDayData, index) in customDaysData"
        :key="customDayData.id"
        :customDayData="customDayData"
        :index="index"
        :updateCustomOpeningHours="updateCustomOpeningHours"
        :discardCustomOpeningHours="discardCustomOpeningHours"
        :deleteCustomOpeningHours="deleteCustomOpeningHours"
        :getCustomOpeningHours="getCustomOpeningHours"
        :filterDays="filterDays"
      />
    </div>
    <br /><br />
  </div>
</template>

<script>
import { MglMap, MglMarker, MglPopup } from 'vue-mapbox'
import MainButton from '@/layout/MainButton.vue'
import MglGeocoderControl from 'vue-mapbox-geocoder'

import OpeningHoursOrdinaryDayCard from '@/components/stores/OpeningHoursOrdinaryDayCard'
import OpeningHoursCustomDayCard from '@/components/stores/OpeningHoursCustomDayCard'
import AddCustomOpeningHourPopupBox from '@/components/stores/AddCustomOpeningHourPopupBox'
import StoreDescription from '@/components/stores/StoreDescription'
import StoreContact from '@/components/stores/StoreContact'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import axios from 'axios'
import SaveDiscardMenu from '@/components/SaveDiscardMenu.vue'

export default {
  components: {
    OpeningHoursOrdinaryDayCard,
    MainButton,
    SaveDiscardMenu,
    DatePicker,
    OpeningHoursCustomDayCard,
    AddCustomOpeningHourPopupBox,
    MglMap,
    MglMarker,
    MglPopup,
    MglGeocoderControl,
    StoreDescription,
    StoreContact
  },
  data() {
    return {
      description: { value: '' },
      descriptionCopy: { value: '' },

      daysData: [],
      daysDataCopy: [],

      customDaysData: [],
      customDaysDataCopy: [],

      contactInfo: [],
      contactInfoCopy: [],

      date: [],
      shop: {},
      display: true,
      coordinates: [],
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      addCustomOpeningHourDateRange: null,
      accessToken:
        process.env.VUE_APP_MAPBOX_TOKEN ||
        'pk.eyJ1IjoiZm9yc3JvYmluIiwiYSI6ImNrcmY0N3Y5cDI2YjMyd3A4bjE0OTR6b2sifQ.s0I764qHdmmWspXeoUQ3Bg',
      defaultInput: 'Bodhgaya'
    }
  },
  computed: {
    isChanged() {
      if (
        JSON.stringify(this.daysData) !== JSON.stringify(this.daysDataCopy) ||
        JSON.stringify(this.description) !==
          JSON.stringify(this.descriptionCopy) ||
        JSON.stringify(this.customDaysData) !==
          JSON.stringify(this.customDaysDataCopy) ||
        JSON.stringify(this.contactInfo) !==
          JSON.stringify(this.contactInfoCopy)
      ) {
        return true
      }
      return false
    },
    descriptionIsChanged() {
      if (
        JSON.stringify(this.description) !==
        JSON.stringify(this.descriptionCopy)
      ) {
        return true
      }
      return false
    }
  },
  created() {
    for (let i = 0; i < 7; i++) {
      this.daysData.push({ day: i, fromTime: 0, toTime: 0, open: false })
    }
    this.getOpeningHours()
    this.getShop()
    this.getCustomOpeningHours()
    this.getCords()
    this.getContactInfo()
  },
  methods: {
    update() {
      if (JSON.stringify(this.daysData) !== JSON.stringify(this.daysDataCopy)) {
        //Update opening hours
        this.updateOpeningHours()
      }

      if (
        JSON.stringify(this.description) !==
        JSON.stringify(this.descriptionCopy)
      ) {
        //Update description
        this.updateDescription()
      }

      if (
        JSON.stringify(this.customDaysData) !==
        JSON.stringify(this.customDaysDataCopy)
      ) {
        //Update custom opening hours
        this.updateCustomOpeningHours()
      }

      if (
        JSON.stringify(this.contactInfo) !==
        JSON.stringify(this.contactInfoCopy)
      ) {
        //Update custom opening hours
        this.updateContactInfo()
      }
    },
    getContactInfo() {
      axios
        .post('settings/getContactInfo', {
          storeId: this.$route.params.storeId
        })
        .then(response => {
          this.contactInfo = JSON.parse(JSON.stringify(response.data))
          this.contactInfoCopy = JSON.parse(JSON.stringify(response.data))
        })
    },
    getCords() {
      axios
        .post('storage/getCords', { id: this.$route.params.storeId })
        .then(response => {
          if (
            response.data[0].longitude == null ||
            response.data[0].latitude == null
          ) {
            this.coordinates = [12.3406236, 60.6957465]
          } else {
            this.coordinates = [
              Number(response.data[0].longitude),
              Number(response.data[0].latitude)
            ]
          }
        })
    },
    dragged(e) {
      this.coordinates[0] = e.marker._lngLat.lat
      this.coordinates[1] = e.marker._lngLat.lng

      axios.post('storage/updateCords', {
        id: this.$route.params.storeId,
        coordinates: this.coordinates
      })
    },
    //Remove shop
    removeShop() {
      axios
        .post('storage/removeShop', { id: this.$route.params.storeId })
        .then(() => {
          this.$router.push({ name: 'Shops' })
        })
    },
    //Filter
    filterDays(index) {
      let sortedArray = this.customDaysData[index].dates.sort(function(a, b) {
        if (new Date(a.customDate) < new Date(b.customDate)) return -1
        else return 1
      })
      this.customDaysData[index].dates = sortedArray
    },
    // Ordianry opening hours
    getOpeningHours() {
      axios
        .post('openingHours/getOpeningHours', {
          id: this.$route.params.storeId
        })
        .then(response => {
          response.data.forEach(opening_hour => {
            Object.assign(opening_hour, {
              open: opening_hour.open == 1 ? true : false
            })
            Object.assign(
              this.daysData[
                this.daysData.findIndex(val => val.day === opening_hour.day)
              ],
              opening_hour
            )
          })

          this.daysDataCopy = JSON.parse(JSON.stringify(this.daysData))
        })
    },
    // Custom opening hours
    updateCustomOpeningHours() {
      axios
        .post('openingHours/updateOpeningHoursCustom', {
          customDaysData: this.customDaysData
        })
        .then(() => {
          this.customDaysDataCopy = JSON.parse(
            JSON.stringify(this.customDaysData)
          )
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCustomOpeningHours() {
      axios
        .post('openingHours/getOpeningHoursCustom', {
          shopId: this.$route.params.storeId
        })
        .then(response => {
          this.customDaysData = JSON.parse(JSON.stringify(response.data))
          this.customDaysDataCopy = JSON.parse(JSON.stringify(response.data))
        })
    },
    deleteCustomOpeningHours(id) {
      var r = confirm(
        'Are you sure you want to delete this custom opening hours?'
      )
      if (r == true) {
        axios
          .post('openingHours/deleteCustomOpeningHour', { id: id })
          .then(response => {
            this.getCustomOpeningHours()
          })
      }
    },
    // Open opening hours popup
    addCustomOpeningHours() {
      this.$store.commit('SET_POPUP', {
        component: AddCustomOpeningHourPopupBox,
        properties: { getCustomOpeningHours: this.getCustomOpeningHours }
      })
    },
    // Fetch information
    getShop() {
      axios
        .post('storage/getShop', { shopId: this.$route.params.storeId })
        .then(response => {
          this.shop = response.data
          this.description.value = JSON.parse(
            JSON.stringify(this.shop.description)
          )
          this.descriptionCopy.value = JSON.parse(
            JSON.stringify(this.shop.description)
          )
        })
    },
    // Updates and removal
    updateContactInfo() {
      axios
        .post('settings/updateContactInfo', {
          shopId: this.$route.params.storeId,
          contactInfo: this.contactInfo,
        })
        .then(() => {
          this.contactInfoCopy = JSON.parse(JSON.stringify(this.contactInfo))
        })
    },
    updateOpeningHours() {
      axios
        .post('openingHours/updateOpeningHoursOrdinary', {
          daysData: this.daysData,
          shopId: this.$route.params.storeId
        })
        .then(() => {
          this.daysDataCopy = JSON.parse(JSON.stringify(this.daysData))
        })
        .catch(err => {
          alert(
            err.response.data.msg +
              ': ' +
              this.getDayName(err.response.data.day)
          )
        })
    },
    // Shop description
    updateDescription() {
      axios
        .post('storage/updateStoreDescription', {
          description: this.description.value,
          shopId: this.$route.params.storeId
        })
        .then(() => {
          this.descriptionCopy = JSON.parse(JSON.stringify(this.description))
        })
    },
    // Misc
    discard() {
      this.daysData = JSON.parse(JSON.stringify(this.daysDataCopy))
      this.description = JSON.parse(JSON.stringify(this.descriptionCopy))
      this.customDaysData = JSON.parse(JSON.stringify(this.customDaysDataCopy))
      this.contactInfo = JSON.parse(JSON.stringify(this.contactInfoCopy))
    },
    discardCustomOpeningHours(index, oldData) {
      this.$set(this.customDaysData, index, JSON.parse(JSON.stringify(oldData)))
    },
    clearDateRange() {
      this.addCustomOpeningHourDateRange = null
    },
    getDayName(day) {
      let dayNames = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]
      return dayNames[day]
    }
  }
}
</script>

<style scoped lang="scss">
.StoreInfo {
  width: calc(50% - 10px);
  position: relative;
}
.shopLocation {
  width: 50%;
}
.storageInformation {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.days {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.customDays {
  position: relative;
  width: 100%;
}
.StoreInformation {
  margin-bottom: 20px;
  display: block;
}
.headerBox {
  display: flex;
  align-items: center;
}
.headerBox h2 {
  font-size: 20pt;
  font-weight: lighter;
  line-height: 25px;
}
.title {
  margin: 5px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 13pt;
  margin-top: 20px;
}
.mapBox {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}
.headerBox ion-icon {
  font-size: 15pt;
  margin: 0 10px;
  color: #a11212;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.headerBox ion-icon:hover {
  color: #7c0a0a;
}
.customOpeningHours {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.customOpeningHours p {
  margin-top: 0;
  margin-right: 15px;
}

@media screen and (max-width: 700px) {
  .mapBox {
    width: 100%;
  }
  .headerBox h2 {
    font-size: 15pt;
  }
  .title {
    font-size: 13pt;
  }
}
</style>
