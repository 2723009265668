<template>
  <div class="day">
    <div class="header">
      <p>{{ getDayName(opening_hour.day) }}</p>
      <md-switch v-model="opening_hour.open" />
    </div>
    <div class="inputTime">
      <input
        :disabled="!opening_hour.open"
        type="time"
        :value="from"
        @blur="updateFrom"
      />
      <input
        :disabled="!opening_hour.open"
        type="time"
        :value="to"
        @blur="updateTo"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['opening_hour'],
  mounted() {

  },
  data() {
    return {

    }
  },
  computed: {
    from() {
      return new Date(this.opening_hour.fromTime * 1000).toISOString().substr(11, 5)
    },
    to() {
      return new Date(this.opening_hour.toTime * 1000).toISOString().substr(11, 5)
    },
  },
  methods: {
    displayToSec(string) {
      let hours = Number(string.split(':')[0])
      let minutes = Number(string.split(':')[1])
      return hours * 3600 + minutes * 60
    },
    updateFrom($event) {
      this.opening_hour.fromTime = this.displayToSec($event.currentTarget.value)
    },
    updateTo($event) {
      this.opening_hour.toTime = this.displayToSec($event.currentTarget.value)
    },
    getDayName(day) {
      let dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return dayNames[day]
    }
  },
  data() {
    return {
      toggledDays: []
    }
  },
}
</script>

<style scoped>
.day {
  padding: 10px;
  background: white;
  margin: 10px;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
  cursor: pointer;
}

.inputTime {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.inputTime input {
  width: 100%;
  position: relative;
  margin: 10px 0;
  border-radius: 3px;
  border: none;
  border-bottom: 1px solid rgb(201, 201, 201);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>