<template>
  <div class="contactInfoWrapper">
    <div class="contactInfo" v-for="(contact, index) in contactInfo" :key="contact.uuid">
      <StandardInput v-model="contact.title" />
      <StandardInput v-model="contact.value" />
      <ion-icon @click="removeContact(index)" name="trash-outline"></ion-icon>
    </div>
    <MainButton
      :clickFunction="addContactInfo"
      title="Add contact info"
      styleClass="primary"
      :buttonStyle="{
        'margin': '7px',
        'height': '50px'
      }"
    />
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton.vue'
import StandardInput from '@/layout/StandardInput.vue'
import AddContactPopupBox from '@/components/stores/AddContactPopupBox'

let uuid = require('uuid')

export default {
  props: ['contactInfo'],
  components: {
    MainButton,
    StandardInput,
    AddContactPopupBox
  },
  methods: {
    addContactInfo() {
      this.$store.commit('SET_POPUP', { component: AddContactPopupBox, properties: { addContact: this.addContact } })
    },
    addContact(data) {
      this.contactInfo.push({
        id: uuid.v4(),
        title: data.title,
        value: data.value,
      })
    },
    removeContact(index) {
      this.contactInfo.splice(index, 1)
    }
  },
  
}
</script>

<style scoped lang="scss">
.contactInfo input:focus {
  border-bottom: 1px solid $accent;
}

.contactInfo input {
  width: auto;
  margin-right: 10px;
  border:none;
  border-radius: 0px;
  border-bottom: 1px solid rgb(182, 182, 182);
}
.contactInfo ion-icon {
  font-size: 18pt;
  color: #333;
  cursor: pointer;
  transition: .2s ease-in-out;
}
.contactInfo ion-icon:hover {color: rgb(255, 82, 82);}

.contactInfo {
  position: relative;
  width: auto;
  height: 50px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
}
.contactInfoWrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}
</style>