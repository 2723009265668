<template>
  <div class="wrapper">
    <textarea class="textArea" placeholder="Shop description" type="text" v-model="description.value" />
  </div>
</template>

<script>
export default {
    props: ['description']
}
</script>

<style scoped>
.wrapper {
   width: 100%;
   position: relative;
   display: flex;
   flex-wrap: wrap;
}
.textArea {
    border: none;
    outline: none;
    padding: 15px;
    width: 100%;
    margin: 5px;
    border-radius: 5px;
}

</style>
