<template>
  <tr class="tableRow">
    <td>{{ formatDateWithDayAndMonthName(customDayDate.customDate) }}</td>
    <td>
      <div style="display: flex">
        <md-switch v-model="enabled" />
        <div v-if="enabled" class="tableInput">
          <input type="time" placeholder="from" v-model="fromTime" />
          <input type="time" placeholder="to" v-model="toTime" />
        </div>
      </div>
    </td>
    <td></td>
    <td style="text-align: right">
      <ion-icon
        style="cursor: pointer; font-size: 15pt"
        @click="deleteDateFromCustomHours(dateIndex)"
        name="trash-outline"
      ></ion-icon>
    </td>
  </tr>
</template>

<script>
import { formatDateWithDayAndMonthName } from '../../globalFunctions/date';
export default {
  props: ['customDayDate', 'deleteDateFromCustomHours', 'dateIndex'],
  data() {
    return {
      fromTime: new Date(this.customDayDate.fromTime * 1000).toISOString().substr(11, 5),
      toTime: new Date(this.customDayDate.toTime * 1000).toISOString().substr(11, 5),
      enabled: (this.customDayDate.enabled == 1 ? true : false)
    }
  },
  // To be able to use MD checkbox and other stuff
  watch: {
    enabled() {
      this.customDayDate.enabled = (this.enabled == true ? 1 : 0)
    },
    fromTime() {
      this.customDayDate.fromTime = this.displayToSec(String(this.fromTime));
    },
    toTime() {
      this.customDayDate.toTime = this.displayToSec(String(this.toTime));
    }
  },
  methods: {
    displayToSec(string) {
      let hours = Number(string.split(':')[0])
      let minutes = Number(string.split(':')[1])
      return hours * 3600 + minutes * 60
    },
    formatDateWithDayAndMonthName,
  },
  computed: {
  }
};
</script>

<style scoped lang="scss">
.tableRow {
  border: 3px solid red;
}

.tableRow:hover {
  background: #fafafa;
}

.tableInput {
  display: flex;
}
.tableInput input {
  border: none;
  border-bottom: 1px solid #636363;
  position: relative;
  margin: 0 15px;
  font-size: 10pt;
  transition: 0.2s ease-in-out;
}
</style>