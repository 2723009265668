<template>
  <div class="popupBox">
    <div class="popContent">
      <div class="popTopRow">
        <ion-icon
          style="cursor: pointer"
          @click="closePopup"
          name="close-outline"
        ></ion-icon>
      </div>
      <div class="popMiddleRow">
        <p>Add custom opening hours?</p>

        <div class="popMiddleInput">
          <p>Write the name, for example <i>New Years Eve</i></p>
          <input type="text" placeholder="Name" v-model="name" />
          <ion-icon name="brush-outline"></ion-icon>
        </div>
        <div class="popMiddleInput">
          <p>Add dates that is affected</p>

          <div class="popCenterObject">
            <p>Select a range of dates</p>
            <md-switch v-model="selectRange" />
          </div>
          <DatePicker
            format="YYYY-MM-DD"
            placeholder="Select dates"
            v-model="time"
            :range="selectRange == 1 ? true : false"
          />
          <MainButton
            :clickFunction="addDate"
            :title="selectRange == 1 ? 'Add dates' : 'Add date'"
            styleClass="secondary"
            :buttonStyle="buttonStyle"
          />
        </div>
        <div class="popMiddleInput">
          <p>Affected dates</p>
          <div class="selectedDatesWrapper">
            <div
              class="selectedDates"
              v-for="(data, index) in selectedDates"
              :key="new Date(data).getTime()"
            >
              <p>{{ String(new Date(data)).substr(0, 15) }}</p>
              <ion-icon
                @click="removeDate(index)"
                name="trash-outline"
              ></ion-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="popBottomRow">
        <MainButton
          :clickFunction="closePopup"
          title="Cancel"
          styleClass="secondary"
          :buttonStyle="buttonStyle"
        />
        <MainButton
          :clickFunction="saveChanges"
          title="Add"
          styleClass="primary"
          :buttonStyle="buttonStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MainButton from '@/layout/MainButton'
import DatePicker from 'vue2-datepicker'

export default {
  components: { MainButton, DatePicker },
  props: ['getCustomOpeningHours'],
  data() {
    return {
      time: null,
      selectedDates: [],
      selectRange: 0,
      name: '',
      buttonStyle: {
        padding: '9px 10px',
        'marginLeft': '10px',
        fontSize: '8pt',
      },
    }
  },
  methods: {
    addDate() {
      if(this.time == null) return
      if (this.selectRange) { // IF mutiple dates
        var getDaysArray = function (s, e) { for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) { a.push(new Date(d)); } return a; }
        getDaysArray(this.time[0], this.time[1]).forEach(date => {
          const found = this.selectedDates.find(arrayDate => String(arrayDate).substr(0, 15) == String(date).substr(0, 15))
          if (!found) {
            this.selectedDates.push(String(date))
          }
        });
      } else {
        const found = this.selectedDates.find(arrayDate => String(arrayDate).substr(0, 15) == String(this.time).substr(0, 15))
        if (!found) {
          this.selectedDates.push(String(this.time))
        }
      }
    },
    removeDate(index) {
      this.selectedDates.splice(index, 1)
    },
    saveChanges() {
      //Check if any dates is slected
      if (this.selectedDates.length == 0) {
        alert("Please add some dates")
      } else {
        axios
          .post('openingHours/addOpeningHoursCustom', { shopId: this.$route.params.storeId, name: this.name, selectedDates: this.selectedDates })
          .then((response) => {
            if (response) {
              this.closePopup()
              this.getCustomOpeningHours()
            }
          })
      }
    },
    closePopup() {
      this.$store.commit('CLEAR_POPUP')
    },
  },
}
</script>

<style scoped src="@/assets/css/popup.css"></style>
<style scoped lang="scss">
.selectedDatesWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  align-items: center;
  justify-content: left;
  position: relative;
}
.popMiddleInput ion-icon {
  position: absolute;
  right: 0px;
  bottom: 50%;
  transform: translateY(100%);
}

.selectedDates {
  display: flex;
  margin-bottom: 5px;
}
.selectedDates p {
  width: 90px;
}
.selectedDates ion-icon {
  position: relative;
  margin-right: 15px;
  top: 50%;
  transform: translateY(25%);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.selectedDates ion-icon:hover {
  color: rgb(160, 0, 0);
}

.popCenterObject {
  display: flex;
  align-items: center;
}
.popCenterObject p {
  margin-right: 10px;
}


.popContent {
  width: 100%;
  position: relative;
  display: inline;
}

/* Pop top row */

.popTopRow {
  width: 100%;
  text-align: right;
  font-size: 24pt;
}

/* Pop middle row */

.popMiddleRow {
  position: relative;
  margin: 10px;
  margin-top: 0px;
}
.popMiddleRow p {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 13pt;
}

.popMiddleInput {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.popMiddleInput input {
  border: none;
  border-bottom: 1px solid rgb(99, 99, 99);
  padding: 3px;
  position: relative;
  width: 100%;
  margin-top: 5px;
  transition: 0.2s ease-in-out;
}

.popMiddleInput input:focus {
  border-bottom: 1px solid $accent;
}

.popMiddleInput p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-size: 9pt;
}

/* Pop bottom row */

.popBottomRow {
  position: relative;
  margin: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
</style>