<template>
  <div class="customDatesWrapper">
    <div class="customDatesWrapperHeader">
      <p>Name:</p>
      <StandardInput type="text" v-model="customDayData.name" />
      <ion-icon
        @click="deleteCustomOpeningHours(customDayData.id)"
        name="trash-outline"
      ></ion-icon>
    </div>
    <div>
      <table class="standardTable">
        <tr class="standardTableHeader">
          <th width="200px">Date</th>
          <th width="500px">Open/closed</th>
        </tr>
        <CustomDatesTable
          :key="
            String(customDayDate.enabled) +
            String(customDayDate.id) +
            String(customDayDate.toTime) +
            String(customDayDate.fromTime)
          "
          :customDayDate="customDayDate"
          :dateIndex="dateIndex"
          :deleteDateFromCustomHours="deleteDateFromCustomHours"
          v-for="(customDayDate, dateIndex) in customDayData.dates"
        />
        <tr class="tableRow">
          <td @click="addNewDate" class="addNewDate" colspan="4">
            <div>
              <ion-icon name="add-circle-outline"></ion-icon>
              <p>Add new date</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import CustomDatesTable from '@/components/stores/CustomDatesTable'
import SaveDiscardMenu from '@/components/SaveDiscardMenu.vue'
import AddDatePopupBox from '@/components/stores/AddDatePopupBox'
import StandardInput from '@/layout/StandardInput'

export default {
  props: ['filterDays', 'customDayData', 'getCustomOpeningHours', 'updateCustomOpeningHours', 'discardCustomOpeningHours', 'deleteCustomOpeningHours', 'index'],
  components: {
    CustomDatesTable,
    SaveDiscardMenu,
    StandardInput
  },
  methods: {
    addNewDate() {
      this.$store.commit('SET_POPUP', {
        component: AddDatePopupBox,
        properties: {
          customDayData: this.customDayData,
          getCustomOpeningHours: this.getCustomOpeningHours,
          discardCustomOpeningHours: this.discardCustomOpeningHours,
          index: this.index,
          filterDays: this.filterDays
        }
      })
    },
    deleteDateFromCustomHours(index) {
      this.customDayData.dates.splice(index, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.addNewDate {
  text-align: center;
  cursor: pointer;
}
.addNewDate:hover {
  -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
}
.addNewDate div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addNewDate div p {
  margin-left: 10px;
}
.addNewDate div ion-icon {
  font-size: 15pt;
  color: $accent;
}
.customDatesWrapper {
  width: 100%;
  padding: 10px;
  margin-right: 10px;
  text-align: center;
  border-radius: 5px;
}
.customDatesWrapperHeader {
  width: 300px;
  display: flex;
  align-items: center;
  margin: 10px;
}
.customDatesWrapperHeader p {
  font-size: 8pt;
  font-family: 'Roboto', sans-serif;
  color: rgb(94, 94, 94);
  font-size: 11pt;
  margin-right: 10px;
}
.customDatesWrapperHeader ion-icon {
  margin-left: 10px;
  font-size: 12pt;
  margin-right: 10px;
  color: rgb(161, 18, 18);
  cursor: pointer;
}
</style>