<template>
  <div class="popupBox">
    <div class="popContent">
      <div class="popTopRow">
        <ion-icon
          style="cursor: pointer"
          @click="closePopup"
          name="close-outline"
        ></ion-icon>
      </div>
      <div class="popMiddleRow">
        <p>Add new date(s)</p>
        <div class="popMiddleInput">
          <div class="popCenterObject">
            <p>Select a range of dates</p>
            <md-switch v-model="selectRange" />
          </div>
          <DatePicker
            placeholder="Select dates"
            v-model="mutipleDays"
            :range="selectRange == 1 ? true : false"
          />
          <MainButton
            :clickFunction="addDate"
            :title="selectRange == 1 ? 'Add dates' : 'Add date'"
            styleClass="secondary"
            :buttonStyle="buttonStyle"
          />
        </div>
        <div class="popMiddleInput">
          <p>Affected dates</p>
          <div class="selectedDatesWrapper">
            <div
              class="selectedDates"
              v-for="(data, index) in customDayData.dates"
              :key="data.id"
            >
              <p>{{ String(new Date(data.customDate)).substr(0, 15) }}</p>
              <ion-icon
                @click="removeDate(index)"
                name="trash-outline"
              ></ion-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="popBottomRow">
        <MainButton
          :clickFunction="closePopup"
          title="Cancel"
          styleClass="secondary"
          :buttonStyle="buttonStyle"
        />
        <MainButton
          :clickFunction="saveChanges"
          title="Add date(s)"
          styleClass="primary"
          :buttonStyle="buttonStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton'
import DatePicker from 'vue2-datepicker'

export default {
  components: { MainButton, DatePicker },
  props: ['filterDays', 'customDayData', 'getCustomOpeningHours', 'discardCustomOpeningHours','index'],
  data() {
    return {
      mutipleDays: null,
      selectRange: 0,
      newDatesAdded: [],
      oldData: JSON.parse(JSON.stringify(this.customDayData)),
      name: '',
      buttonStyle: {
        padding: '9px 10px',
        'marginLeft': '10px',
        fontSize: '8pt',
      },
    }
  },
  methods: {
    addDate() {
      if(this.mutipleDays == null) return

      if (this.selectRange) { // If mutiple dates
        var getDaysArray = function (s, e) { for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) { a.push(new Date(d)); } return a; }
        getDaysArray(this.mutipleDays[0], this.mutipleDays[1]).forEach(date => {
          const found = this.customDayData.dates.find(arrayDate => String(new Date(arrayDate)).substr(0, 15) == String(date).substr(0, 15))
          if (!found) {
            let tempDateObj = {
              customDate: String(date),
              fromTime: null,
              toTime: null,
              enabled: 0
            }
            this.customDayData.dates.push(tempDateObj)
            this.newDatesAdded.push(tempDateObj)
          }
        });
      } else {
        const found = this.customDayData.dates.find(arrayDate => String(new Date(arrayDate.customDate)).substr(0, 15) == String(this.mutipleDays).substr(0, 15))
        if (!found) {
          let tempDateObj = {
            customDate: String(this.mutipleDays),
            fromTime: null,
            toTime: null,
            enabled: 0
          }
          this.customDayData.dates.push(tempDateObj)
          this.newDatesAdded.push(tempDateObj)

        }
      }
      this.filterDays(this.index)
    },
    removeDate(index) {
      this.customDayData.dates.splice(index, 1)
    },
    saveChanges() {
      this.$store.commit('CLEAR_POPUP')
    },
    closePopup() {
      this.discardCustomOpeningHours(this.index, this.oldData)
      this.$store.commit('CLEAR_POPUP')
    },
  },
}
</script>

<style scoped src="@/assets/css/popup.css"></style>
<style scoped lang="scss">
.selectedDatesWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  align-items: center;
  justify-content: left;
  position: relative;
}
.popMiddleInput ion-icon {
  position: absolute;
  right: 0px;
  bottom: 50%;
  transform: translateY(100%);
}

.selectedDates {
  display: flex;
  margin-bottom: 5px;
}
.selectedDates p {
  width: 90px;
}
.selectedDates ion-icon {
  position: relative;
  margin-right: 15px;
  top: 50%;
  transform: translateY(25%);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.selectedDates ion-icon:hover {
  color: rgb(160, 0, 0);
}

.popCenterObject {
  display: flex;
  align-items: center;
}
.popCenterObject p {
  margin-right: 10px;
}
.centerBlock {
  width: 360px;
  border-radius: 10px;
  background: white;
  position: relative;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.popContent {
  width: 100%;
  position: relative;
  display: inline;
}

/* Pop top row */

.popTopRow {
  width: 100%;
  text-align: right;
  font-size: 24pt;
}

/* Pop middle row */

.popMiddleRow {
  position: relative;
  margin: 10px;
  margin-top: 0px;
}
.popMiddleRow p {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 13pt;
}

.popMiddleInput {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.popMiddleInput input {
  border: none;
  border-bottom: 1px solid rgb(99, 99, 99);
  padding: 3px;
  position: relative;
  width: 100%;
  margin-top: 5px;
  transition: 0.2s ease-in-out;
}

.popMiddleInput input:focus {
  border-bottom: 1px solid $accent;
}

.popMiddleInput p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-size: 9pt;
}

/* Pop bottom row */

.popBottomRow {
  position: relative;
  margin: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
</style>